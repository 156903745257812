import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Accountsummary from "./pages/account-summary/account-summary";
import Home from "./pages/home/home";
import './App.css';

class App extends React.Component {
  render() {
  return (
    <div className="App">
      <Router>
        <div>
          <Switch>
            <Route exact path="/account-summary">
              <Accountsummary />
            </Route>

            <Route exact path="/:referral?">
              <Home></Home>
            </Route>         
            
          </Switch>
        </div>
      </Router>
    </div>
  );
  }
}

export default App;
