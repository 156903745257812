import React, { Component } from 'react';
import Header from "./header";
import Footer from "./footer";


class Frame extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        const { withHeader, withFooter } = this.props;
      return (
        <div>
           {withHeader && <Header></Header> } 
            <main class="main_inner">
              <div class="main_inner_container">
              
              
              
                {this.props.children}

                {withFooter &&  <Footer></Footer>}
                

                </div>
            </main>
        </div>
      );
    }
  }

  export default Frame;