import React, { Component } from 'react';
import Frame from "../../components/frame";

class Accountsummary extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <Frame withHeader={true} withFooter={true}>
         <div className="text-right pt_20">
            <a className="top_grad_btn account_mobile_btn" href="/"><i className="fa fa-long-arrow-left"></i> Go back to home</a>
          </div>

          <div className="pb_50 pt_50">
            <div className="row sm_Personal_p">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Total Bonus</h4>
                  <p>Amount </p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Sponsor Bonus</h4>
                  <p>Amount </p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Upline Bonus</h4>
                  <p>Net Profit </p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Downline Bonus </h4>
                  <p>Net Profit</p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Binary Bonus</h4>
                  <p>Net Profit </p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Your Subscription</h4>
                  <p>Amount</p>
                  <h5>0.00 TRX</h5>
                </div>
              </div>
            
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Subscription History</h2>
            </div>
            <div className="table_inner">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>User ID</th>
                      <th>Investment</th>
                      <th>Income</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>5456</td>
                      <td>TRX</td>
                      <td>----</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>5456</td>
                      <td>TRX</td>
                      <td>----</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>5456</td>
                      <td>TRX</td>
                      <td>----</td>
                    </tr>
                  
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Withdraw History</h2>
            </div>
            <div className="table_inner">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th> Level </th>
                      <th> Count </th>
                      <th> Invest Amount </th>
                      <th> Percentage </th>
                      <th> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                  <td> 4 </td>
                  <td> 5 </td>
                  <td> 6173.2 TRX</td>
                  <td> 3.00 %</td>
                  <td> 185.196 TRX</td>
                  </tr>
                  <tr>
                  <td> 5 </td>
                  <td> 3 </td>
                  <td> 313.2 TRX</td>
                  <td> 10.00 %</td>
                  <td> 31.32 TRX</td>
                  </tr>
                  <tr>
                  <td> 6 </td>
                  <td> 2 </td>
                  <td> 1324 TRX</td>
                  <td> 2.00 %</td>
                  <td> 26.48 TRX</td>
                  </tr>
                  <tr>
                  <td> 7 </td>
                  <td> 1 </td>
                  <td> 2120 TRX</td>
                  <td> 2.00 %</td>
                  <td> 42.4 TRX</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Binary Payout Log</h2>
            </div>
            <div className="table_inner">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th> Level </th>
                      <th> Count </th>
                      <th> Invest Amount </th>
                      <th> Percentage </th>
                      <th> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                  <td> 4 </td>
                  <td> 5 </td>
                  <td> 6173.2 TRX</td>
                  <td> 3.00 %</td>
                  <td> 185.196 TRX</td>
                  </tr>
                  <tr>
                  <td> 5 </td>
                  <td> 3 </td>
                  <td> 313.2 TRX</td>
                  <td> 10.00 %</td>
                  <td> 31.32 TRX</td>
                  </tr>
                  <tr>
                  <td> 6 </td>
                  <td> 2 </td>
                  <td> 1324 TRX</td>
                  <td> 2.00 %</td>
                  <td> 26.48 TRX</td>
                  </tr>
                  <tr>
                  <td> 7 </td>
                  <td> 1 </td>
                  <td> 2120 TRX</td>
                  <td> 2.00 %</td>
                  <td> 42.4 TRX</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        
          <div className="pb_50">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner Personal_bg">
                  <h4>Remaining Left Point </h4>
                  <h5>0</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Total Left Point </h4>
                  <h5>0</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  <h4>Left User Count </h4>
                  <h5>0</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Remaining Right Point </h4>
                  <h5>0</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Total Right Point </h4>
                  <h5>0</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  <h4>Right User Count </h4>
                  <h5>0</h5>
                </div>
              </div>
            </div>
          </div>

         

        </Frame>
      );
    }
  }

  export default Accountsummary;