import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div className="">
         <footer className="pt-5 pb-5">
            <div className="container">
              <div className="d-flex justify-content-between">
                <img className="footer_logo" src="img/logo.png" alt="" />
                <p className="copy_text">&copy; 2022 millionmatic All Rights Reserved. </p>
              </div>
            </div>
          </footer>
  
        <div className="overlay">
          <div className="overlayDoor"></div>
          <div className="overlayContent">
            <img src="img/logo.png" alt="" />
            <p>Loading.....</p>
          </div>
        </div>
        </div>
      );
    }
  }

  export default Footer;