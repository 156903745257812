import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container">
            <div className="d-flex justify-content-between">
              <a href="/">
                <img className="logo_icon" src="img/logo_icon.png" alt="" />
              </a>
            
              <a className="all_btn" target="_blank" href="https://polygonscan.com/address/0x4A293CDE74ec33c769DA1F7117A2C101068f6b28">Verified Contract </a>
            </div>
          </div>
      </header>
      );
    }
  }

  export default Header;