import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MillionMatic from "../../contracts/MillionMatic.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['25','50','100','200','500'];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      poolDepositTotal:0,
      poolDeposit:0,
      poolQualifierCount:0,
      GetPoolIncome:0,
      GetPoolIncomeTwo:0,
      _userUplineIncome:0,
      _userDownlineIncome:0,
      poolDepositTotalTwo:0,
      poolDepositTwo:0,
      poolQualifierCountTwo:0,
      top_referrer:[]
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = MillionMatic.networks;
        const instance = new web3.eth.Contract(
          MillionMatic.abi,
          deployedNetwork && MillionMatic.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:MillionMatic.address, loading:false}, ()=>
          {
            setTimeout(() => {
              this.fetchData();
            }, 5000);
          }
          
          );
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    try{

      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();    
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
      userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
      userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
      let user_referer = '---------';
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }

      // let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
      // GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
      // GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);

      // let GetPoolIncomeTwo = await contract.methods.GetPoolIncomeTwo(accounts).call();
      // GetPoolIncomeTwo = this.state.web3.utils.fromWei(GetPoolIncomeTwo[0], 'ether');
      // GetPoolIncomeTwo = parseFloat(GetPoolIncomeTwo).toFixed(5);
      
      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      TotalBonus = parseFloat(TotalBonus).toFixed(5);
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
      let totalDeposits = await contract.methods.totalDeposits().call();
      totalDeposits = parseFloat(totalDeposits).toFixed(5);
      let totalInvested = await contract.methods.totalInvested().call();
      totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
      totalInvested = parseFloat(totalInvested).toFixed(5);
      let totalUsers = await contract.methods.totalUsers().call();

      let poolDepositTotal = await contract.methods.poolDepositTotal().call();
      poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
      poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);

      let poolDepositTotalTwo = await contract.methods.poolDepositTotalTwo().call();
      poolDepositTotalTwo = this.state.web3.utils.fromWei(poolDepositTotalTwo, 'ether');
      poolDepositTotalTwo = parseFloat(poolDepositTotalTwo).toFixed(5);

      let poolDeposit = await contract.methods.poolDeposit().call();
      poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
      poolDeposit = parseFloat(poolDeposit).toFixed(5);

      let poolDepositTwo = await contract.methods.poolDepositTwo().call();
      poolDepositTwo = this.state.web3.utils.fromWei(poolDepositTwo, 'ether');
      poolDepositTwo = parseFloat(poolDepositTwo).toFixed(5);

      // let poolQualifierCount = await contract.methods.poolQualifierCount().call();
      // let poolQualifierCountTwo = await contract.methods.poolQualifierTwoCount().call();
      

      let balance = TotalBonus;
      let adminFee = balance*this.state.adminFee_percentage/100;
      balance = balance - adminFee;


      let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
      let reinvest_percentage = getEligibleWithdrawal.reivest;
      let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

      let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
      let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

      // let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
      // GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
      // GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
      // GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

      // let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
      // GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
      // GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
      // GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

      let _userUplineIncome = await contract.methods._userUplineIncome(accounts).call();
      _userUplineIncome = this.state.web3.utils.fromWei(_userUplineIncome, 'ether');
      _userUplineIncome = (_userUplineIncome-singleUplineBonusTaken);
      _userUplineIncome = parseFloat(_userUplineIncome).toFixed(5);

      let singleDownlineBonus = this.state.web3.utils.fromWei(userInfo.singleDownlineBonus, 'ether');
      let _userDownlineIncome = (singleDownlineBonus-singleDownlineBonusTaken);
      _userDownlineIncome = parseFloat(_userDownlineIncome).toFixed(5);

      /* Top Referrer Section Start */
      let top_referrer = [];
      let daily_balance = await contract.methods.daily_balance().call();    
      if(parseInt(daily_balance) > 0){
        let daily_top_cycle = await contract.methods.daily_top_cycle().call();
        for(let i=0;i<=2;i++){
          let daily_top_address = await contract.methods.daily_top(i).call();
          let daily_total_referrer = await contract.methods.daily_users_refs_deposits_sum(daily_top_cycle,daily_top_address).call();
          daily_total_referrer = this.state.web3.utils.fromWei(daily_total_referrer, 'ether');
          daily_total_referrer = parseFloat(daily_total_referrer).toFixed(5);
          let daily_win = 0;
          if(i === 0){
            daily_win = parseFloat(daily_total_referrer*5/100).toFixed(5);
          }else if(i === 1){
            daily_win = parseFloat(daily_total_referrer*3/100).toFixed(5);
          }else if(i === 2){
            daily_win = parseFloat(daily_total_referrer*2/100).toFixed(5);
          }
          top_referrer.push({level:i,daily_top_address:daily_top_address,daily_total_referrer:daily_total_referrer,daily_win:daily_win});
        }
      }
      /* Top Referrer Section End */
      
      this.setState({ 
        userInfo,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        totalInvested,
        totalUsers,
        poolDepositTotal,
        poolDepositTotalTwo,
        poolDeposit,
        poolDepositTwo,
        // poolQualifierCount,
        // poolQualifierCountTwo,
        // GetPoolIncome,
        // GetPoolIncomeTwo,
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        user_referer:user_referer,
        balance,
        adminFee,
        reinvest_percentage,
        withdrwal_percentage,
        //GetUplineIncomeByUserId,
        //GetDownlineIncomeByUserId,
        _userUplineIncome,
        _userDownlineIncome,
        top_referrer,
        loading:false
      },async()=>{
        const { accounts, contract } = this.state;
        // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
        let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
        let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
        let communityLevels = [];
        let upline_users = [];
        let downline_users = [];
        let current_user = accounts;
        let userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=uplineCount;i++){          
          if(current_user == userInfo.singleUpline) continue;
          current_user = userInfo.singleUpline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
        }
        upline_users.sort((a, b) => b.level>a.level? 1 : -1);
        upline_users.map(function(val, index){
          communityLevels.push(val);
        });
        let currentUserInfo = await contract.methods.users(accounts).call();
        let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        income = (0).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
        communityLevels.push(main_user);
        current_user = accounts;
        userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=downlineCount;i++){
          if(current_user == userInfo.singleDownline) continue;
          current_user = userInfo.singleDownline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
        }
        downline_users.map(function(val, index){
          communityLevels.push(val);
        });

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<5;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
          //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
          let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
          _investment = parseFloat(_investment).toFixed(5);
          percentage_amount = parseFloat(percentage_amount).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;
          
          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          let requireText = '';
          if((i>=2 && i<5) && count<4){
            status = 'Unqualified';
            requireText = 'require '+4 + ' direct';
          }
          sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        
        //this.setState({sponsorList});
        this.setState({communityLevels, sponsorList});
        // console.log(communityLevels);      
        // console.log(sponsorList);       
      });
    }catch (error) {
      console.log(error);
      if (error.message.includes("error")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
      }     
    }
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(parseInt(balanceEthVal) > parseInt(amountBNB)){
        try {
          this.setState({loading:true});
          this.state.contract.events.NewDeposit((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});
      this.state.contract.events.Withdrawn((err, eventResult) => {
        console.log('eventResult',eventResult);
        console.log('err',err);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
        }        
      });

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );
      //console.log('withdrawal',withdrawal); 
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame withHeader={true} withFooter={true}>
        
        {this.state.loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (null)}

        
          <section className="banner_section">
            <img className="banner_bg1" src="img/banner_bg1.png" alt="" />
            <img className="banner_left_img" src="img/banner_left.png" alt="" />
            <div className="container">
              <div className="banner_text">
                <div className="row">
                  <div className="col-md-7 col-sm-6 col-lg-7">
                    <div className="banner_text_width">
                      <img className="banner_logo" src="img/logo.png" alt="" />
                      <h1>Versatile crypto investment platform!</h1>
                      <p>Trade between cryptocurrencies in one easy step and grow your crypto. Our exchange makes it simple to research the crypto market to buy and sell cryptos, and build a portfolio for the future of money.</p>
                      <h4 className="referal_text word-break">
                      <CopyToClipboard text={`https://millionmatic.com/${this.state.YourAddressfull}`}
                        onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                        }>
                       <button><img src="img/copy.png" alt="" /> </button> 
                      </CopyToClipboard>
                         https://millionmatic.com/{this.state.YourAddress}
                      
                      
                      </h4>
                      
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-6 col-lg-5">
                    <div className="banner_deposit_margin">
                      <ul className="deposit_ul">
                        <li>
                          <div>
                            <h4>Total Deposit</h4>
                            <img src="img/icon1.png" alt="" />
                            <h5>{this.state.userInfo.amount} MATIC</h5>
                          </div>
                        </li>
                        <li>
                          <div className="big_size">
                            <h4>Total Withdrawn</h4>
                            <img src="img/icon2.png" alt="" />
                            <h5>{this.state.userInfo.totalWithdrawn} MATIC</h5>
                          </div>
                        </li>
                      </ul>
                      <ul className="deposit_ul">
                        <li>
                          <div className="big_size">
                            <h4>Referred By</h4>
                            <img src="img/icon3.png" alt="" />
                            <h6>{this.state.user_referer}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="id_size">
                            <h4>ID</h4>
                            <img src="img/icon4.png" alt="" />
                            <small>{this.state.YourAddress}</small>
                          </div>
                        </li>
                        
                      </ul>
                    
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </section>

          <section className="pt-5 pb-5">
            <div className="container">
              <div className="all_heading text-center">
                <img src="img/heading-left.png" alt="" />
                <h3>Join Us Now!</h3>
                <img src="img/heading-right.png" alt="" />
              </div>
              <div className="sm_container">
                <div className="form-group po_rel">
                  {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                    <input className="cus_input" type="text" placeholder="Sponsor Address"
                      readOnly
                      value={this.state.user_referer}
                      onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                    />
                  ):(
                    <input className="cus_input" type="text" placeholder="Sponsor Addresss"
                      value={this.state.sponsor_address}
                      onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                    />
                  )}
                   <button onClick={this.doJoinNow} className="all_btn join_now_btn">Join Now</button>
                </div>
                <ul className="trx_btn mb_20">
                  {defaultAmountArr.map((amount,key) => {return(
                    <li key={key}>
                      <button className={this.state.amountBNB==amount?'active':'inactive'}
                        onClick={e => this.setState({amountBNB:amount})}>
                        {amount+' MATIC'}
                      </button>
                    </li>
                    )                              
                  })}
                </ul>
              </div>
            </div>
          </section>

          <section className="pt-0 pb-5">
            <div className="container">
              <div className="white_bg">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="about_box">
                      <h4><strong>Millionmatic - Offering end-to-end investment solutions!</strong></h4>
                      <br />
                      <p>We are an investment platform helping you make the right investment decisions based solely on the principles of technological understanding and finance. Our platform is developed to minimize losses through an investment distribution system that works between various marketplaces and other metrics.</p>
                      <p>Scrutinizing everything is what matters for us, so we research more and monitor your investments using in-house analysts with blockchain industry knowledge. At Millionmatic our mission is to help you invest in and contribute value to the crypto domain and help you pursue a sustainable investment plan in accordance with the market’s investment mandates. </p>
                      <ul className="banner_ul">
                        <li>
                          <img src="img/trade1.png" alt="" />
                          <p>Decentralized</p>
                        </li>
                        <li>
                          <img src="img/trade2.png" alt="" />
                          <p>Easy-to-use</p>
                        </li>
                        <li>
                          <img src="img/trade3.png" alt="" />
                          <p>Secure</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="video_box">
                      <img src="img/video_bg.png" alt="" />
                      <a href="#" className="all_btn"><i className="fa fa-play" ></i> Play Video</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-0 pb-5">
            <div className="container">
              <div className="all_heading text-center">
                <img src="img/heading-left.png" alt="" />
                <h3>Top Referar</h3>
                <img src="img/heading-right.png" alt="" />
              </div>
                <div className="teble-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="col-4">Address</th>
                        <th className="col-4">Total Refer</th>
                        <th className="col-4">Win</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.top_referrer.length ? (
                        this.state.top_referrer.length>0 ? (
                          this.state.top_referrer.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            return (
                              <tr key={`tr${index}`} className={class_name}>
                                <td className="col-4">
                                  {val.daily_top_address.slice(0, 15) + '...'}
                                </td>
                                <td className="col-4">{val.daily_total_referrer}</td>
                                <td className="col-4">{val.daily_win}</td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                        <tr>
                          <td className="col-4">----------</td>
                          <td className="col-4">----------</td>
                          <td className="col-4">----------</td>
                        </tr>
                      )}              
                    </tbody>
                  </table>
                </div>  
            </div>
          </section>

          {/* <section className="pt-0 pb-5">
            <div className="container">
              <div className="row">
                <div class="col-md-4 col-sm-4 col-lg-4">
                  <div className="all_heading">
                    <h3>Top Referar</h3>
                  </div>
                </div>
                <div class="col-md-8 col-sm-8 col-lg-8">
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Address</h4>
                      {this.state.top_referrer.length > 0 ? (
                        this.state.top_referrer.map((item,key) => {return(
                          <h6 key={`address${key}`}>{item.daily_top_address}</h6>
                          )
                        })
                      ) : (
                        <h6>----------</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Total Refer</h4>
                      {this.state.top_referrer.length > 0 ? (
                        this.state.top_referrer.map((item,key) => {return(
                          <h6 key={`total_ref${key}`}>{item.daily_total_referrer}</h6>
                          )
                        })
                      ) : (
                        <h6>----------</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                    <div className="Community_box">
                      <h4>Win</h4>
                      {this.state.top_referrer.length > 0 ? (
                        this.state.top_referrer.map((item,key) => {return(
                          <h6 key={`win${key}`}>{item.daily_win}</h6>
                          )
                        })
                      ) : (
                        <h6>----------</h6>
                      )}
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <hr></hr>
            </div>
          </section> */}

          <section className="pt-0 pb-5">
            <div className="container">
              <div className="">
                <div className="all_heading text-center">
                  <img src="img/heading-left.png" alt="" />
                  <h3>Read your investment details here!</h3>
                  <img src="img/heading-right.png" alt="" />
                </div>
                
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Community Level</h4>
                      <p>Upline Income</p>
                      <h5>{this.state._userUplineIncome} MATIC</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Community Level</h4>
                      <p>Downline Income</p>
                      <h5>{this.state._userDownlineIncome} MATIC</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                    <div className="Community_box">
                      <h4>Affiliate Level</h4>
                      <p>Sponsor Income</p>
                      <h5>{this.state.userInfo.referrerBonus} MATIC</h5>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </section>

          <section className="pt-0 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 middle_text">
                  <div className="all_heading">
                    <img src="img/heading-left.png" alt="" />
                    <h3>Wallet Balance</h3>
                    <img src="img/heading-right.png" alt="" />
                  </div>
                  <h4><strong>Digital wallet – A next-gen crypto holding solution! </strong></h4>
                    <br />
                    <p>Use a feature-filled and smart digital wallet that is best used for money deposits, withdrawals, transfers, and payments. Easy operability of the versatility of a wallet makes it the best tool to store and transact cryptocurrencies with total security and performance benchmarks as per industry standards.</p>
                    <p>Using the effective and powerful digital wallet you can get detailed payment reports, statistics of payments, and refunds in real-time. Heightened security of the wallet with the integration of verification steps and frequent third-party audits makes sure that your crypto remains safe regardless of the circumstances.</p>
                    
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                <p className="percentage_text">Reinvestment percentage: <span>{this.state.reinvest_percentage}%</span></p>
                <p className="percentage_text">Withdrawable percentage: <span>{this.state.withdrwal_percentage}% </span></p>
        
          
                  <ul className="deposit_ul">
                    <li>
                      <div>
                        <h4>WALLET BALANCE</h4>
                        <img src="img/icon5.png" alt="" />
                        <input className="input_wallet" type="text" readOnly value={this.state.balance} />
                      </div>
                    </li>
                    <li>
                      <div className="id_size">
                        <h4>Reinvestment Amount</h4>
                        <img src="img/icon6.png" alt="" />
                        <input className="input_wallet" type="text" readOnly 
                            value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)}                
                          />
                      </div>
                    </li>
                  </ul>
                  <ul className="deposit_ul">
                    <li>
                      <div className="id_size">
                        <h4>Withdrawable Amount</h4>
                        <img src="img/icon7.png" alt="" />
                        <input className="input_wallet" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)}                
                        />
                      </div>
                    </li>
                    <li>
                      <button onClick={this.doWithdrawal} className="withdrawal_btn"><img src="img/withdrawal.png" alt="" /></button>
                    </li>
                  </ul>
              </div>
              </div>
            </div>
          </section>

          {/* <section className="pt-0 pb-5">
            <div className="container">
              <div className="">
                <div className="all_heading text-center">
                  <img src="img/heading-left.png" alt="" />
                  <h3>Global Details</h3>
                  <img src="img/heading-right.png" alt="" />
                </div>
                
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Total Community</h4>
                      <h5>{this.state.totalUsers}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="Community_box">
                      <h4>Total Invested</h4>
                      <h5>{this.state.totalInvested}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                    <div className="Community_box">
                      <h4>Total Withdrawal</h4>
                      <h5>{this.state.totalWithdrawn}</h5>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </section> */}


        <section className="pt-0 pb-5">
          <div className="container">
            <div className="all_heading text-center">
              <img src="img/heading-left.png" alt="" />
              <h3>Community Level</h3>
              <img src="img/heading-right.png" alt="" />
            </div>
              <div className="teble-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Level</th>
                      <th className="col-6">User ID</th>
                      <th className="col-3">Investment</th>
                      {/* <th className="col-3">Income</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.communityLevels.length ? (
                      this.state.communityLevels.length>0 ? (
                        this.state.communityLevels.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td className="col-3">{val.levelText}</td>
                              <td className="col-6">
                                {val.username.slice(0, 15) + '...'}
                              </td>
                              <td className="col-3">{val.investment} MATIC</td>
                              {/* <td className="col-3">{val.income} MATIC</td> */}
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}              
                  </tbody>
                </table>
              </div>  
          </div>
        </section>

        <section className="pt-0 pb-5">
          <div className="container">
            <div className="all_heading text-center">
              <img src="img/heading-left.png" alt="" />
              <h3>My sponsor List</h3>
              <img src="img/heading-right.png" alt="" />
            </div>
            <div className="teble-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3"> Level </th>
                      <th className="col-3"> Count </th>
                      <th className="col-3"> Invest Amount </th>
                      <th className="col-3"> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sponsorList.length ? (
                      this.state.sponsorList.length>0 ? (
                        this.state.sponsorList.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`sl${index}`} className={class_name}>
                              <td className="col-3"> Level-{val.level} 
                                {/* <span style={{color:'#15d589'}}>{val.requireText?(`(${val.requireText})`):(null)}</span> */}
                              </td>
                              <td className="col-3">{val._noOfUser}</td>
                              <td className="col-3">{val._investment} MATIC</td>
                              <td className="col-3">{val.percentage_amount} MATIC 
                                {/* {val.status?(`(${val.status})`):(null)} */}
                              </td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}
                  </tbody>
                </table>    
              </div>
          </div>
        </section>


      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));